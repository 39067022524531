import { ReactNode } from 'react'

export interface Props {
  top?: boolean
  bottom?: boolean
  children: ReactNode
}

export const useContent = (props: Props) => {
  const { top = true, bottom = true, children } = props

  return {
    top,
    bottom,
    children,
  }
}
