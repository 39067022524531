import { FC } from 'react';
import clsx from 'clsx';
import { useContent, Props } from './useContent';
import styles from './Content.module.css';

export const Content: FC<Props> = (props: Props) => {
  const { children, top, bottom } = useContent(props);

  return (
    <div className={clsx(top && styles.top, bottom && styles.bottom)}>
      {children}
    </div>);

};